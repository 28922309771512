import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventSystem } from '../../events/EventSystem';
import { FCWithChildren } from '../../types/FCWithChildren';
import { SupportedLanguage, supportedLanguages } from '../../types/Languages';
import DropdownSelect from './form-control/DropdownSelect';
import { useCurrentUser } from '../../global-state/Auth';

type LanguageSelectorProps = {
  onLanguageChange?: (code: SupportedLanguage) => void;
  setCurrentUserLanguage?: boolean;
  label?: string;
  value?: SupportedLanguage;
};

const LanguageSelector: FCWithChildren<LanguageSelectorProps> = (props) => {
  const { setCurrentUserLanguage = true, onLanguageChange, label, value } = props;
  const currentUser = useCurrentUser((x) => x.value);

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    if (currentUser && currentUser?.language) {
      return currentUser.language;
    }

    return i18next.language;
  });

  useEffect(function langaugeChanged() {
    const handler = (languageCode: SupportedLanguage) => {
      setCurrentLanguage(languageCode);
    };

    EventSystem.listen('language-changed', handler);
    return () => {
      EventSystem.stopListening('language-changed', handler);
    };
  }, []);

  const { t } = useTranslation('common');

  const languageOptions = useMemo(() => {
    return supportedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`languages.${lang.id}`),
    }));
  }, [t]);

  const selectedLanguageOption = useMemo(
    () => languageOptions.find((x) => x.id === (value || currentLanguage)),
    [currentLanguage, languageOptions, value],
  );

  const changeLanguage = useCallback(
    (code: SupportedLanguage) => {
      if (setCurrentUserLanguage) {
        i18next.changeLanguage(code);
      }
      setCurrentLanguage(code);
      onLanguageChange && onLanguageChange(code);
    },
    [onLanguageChange, setCurrentUserLanguage],
  );

  return (
    <DropdownSelect
      options={languageOptions}
      value={selectedLanguageOption}
      onChange={(o) => changeLanguage(o.id)}
      label={label}
      data-cy="language-selector"
      aria-label={label || t('aria-label.language-selector')}
    />
  );
};

export default LanguageSelector;
